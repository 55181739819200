<script>
import { useRouter } from "vue-router";
import { ApiService } from "../../helpers/api.service.js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { onMounted, ref } from "vue";
import MassActionsModals from '@/components/massActionsModals/index';

export default {
  setup() {
    const loading = ref(true);
    const route = useRouter();
    const body = {
        id_programa: 'e7985cfb-b2bc-4df9-b146-12cc9d6cbbfa', // id_programa chamado
        condicao_adicional: "",
        lista_id_empresa:
            JSON.parse(localStorage.getItem("companiesGlobalFilter")) ?? [],
        navegador: false,
        ordenacao: null,
        quantidade_registros: 1,
        pagina: 0,
        rota: "obtem_dados_programa_usuario",
    };

    onMounted(
      (async () => {
        await ApiService({
            method: "POST",
            url: `/program`,
            data: body,
            headers: {
            authorization: JSON.parse(localStorage.getItem("user")).token,
            },
        })
        .then(res => {
            console.log('aaaa');
            if(res.data.status){
              route.push({ name: "dynamic-home-program", params: { targetId: res.data.retorno.sis_programa.id_programa } });
            }else{
              loading.value = false;
            }
        })
        .catch(err => {
          console.log('bbbbb');
          console.log(err);
          loading.value = false;
        });
      })()
    )

    return {
      loading,
    };
  },
  components: {
    PageHeader,
    Layout,
    MassActionsModals,
  },
  computed: {
    statemode() {
      return this.$store.state.layout.mode;
    },
}
};
</script>

<template>
  <Layout>
    <PageHeader title="Home" />
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center"
      style="
        position: absolute;
        left: 249px;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      "
    >
        <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Carregando...</span>
        </div>
    </div>
    <div
      v-else
      style="height: 75vh"
      class="d-flex justify-content-center align-items-center"
    >
      <img
        v-if="statemode == 'light'"
        src="@/assets/images/logos/ApoioPreto.png"
        alt=""
        height=""
        style="width: 100%; max-width: 300px"
      />
      <img
        v-if="statemode == 'dark'"
        src="@/assets/images/logos/ApoioBranco.png"
        alt=""
        height=""
        style="width: 100%; max-width: 300px"
      />
    </div>
    <MassActionsModals />
  </Layout>
</template>
<style scoped>
</style>
